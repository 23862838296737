import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
// import '../Forget Password/forgetpassword.css'
import { toastError } from "../../Redux/Reducers/admin/adminPanelSlice";
import { resetPassword } from "../../Redux/Actions/user/auth";

export default function ChangePassword() {
  const dispatch = useDispatch();
  const [password, setPassword] = useState({
    password: "",
    confirmPassword: "",
  });
  const { resetToken } = useParams();
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setPassword((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (password.password === password.confirmPassword) {
      dispatch(resetPassword({ password: password.password, resetToken })).then(
        navigate("/")
      );
    } else {
      toastError("Passwords doesn't match");
    }
  };
  return (
    <>
      <section className="wrapper_login login_page">
        <div className="container-fluid">
          <div className="container_data">
            <div className="row ">
              <div className="col-md-12">
                <div className="navbar_set ">
                  <a href="/">
                    <img className='ms-lg-5'
                      src={require("../../Assets/Images/logo.png")}
                      alt="logo"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="row login_page ">
              <div className="col-xl-8 col-lg-7 col-md-6 col-sm-12 text_flex_end">
                <div className="login_desc  pb-5">
                  <h4>Our Vision:</h4>
                  <p className="mt-3">
                    Our aim is to diagnose your dental problem and get you out of pain as soon as possible. Sign in to access your personalised patient dashboard where you can book an online consultation with a Toothaid Dentist.
                  </p>
                </div>
              </div>
              <div className="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                <div className="sign_in">
                  <form
                    onSubmit={handleSubmit}
                    className="w-100">
                    <div className="card">
                      <div className="card-body">
                        <div className="title_signin">
                          <h1>Change Password</h1>

                          <div className="form-group mb-3 mt-4">
                            <label for="">New Password</label>
                            <input
                              type="password"
                              name="password"
                              className="form-control"
                              placeholder="New Password"
                              minLength={8}
                              value={password.password}
                              onChange={handleChange}
                              required={true}
                            />

                            <label for="">Confirm Password</label>
                            <input
                              type="password"
                              name="confirmPassword"
                              className="form-control"
                              placeholder="Confirm Password"
                              minLength={8}
                              value={password.confirmPassword}
                              onChange={handleChange}
                              required={true}
                            />

                          </div>

                          <div className="btn_submit mt-5">
                            <button
                              type="submit"
                              className="btn btn-primary btn-custom btn-lg w-100"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
