import Main from '../Main/Main'
// import '../Clients/clients.css'
import searchImg from '../../Assets/Icons/search.svg'
import { Table } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { formatDateToDMY } from '../../Utils/commonFiles'
import ViewPrescription from '../View Prescription/ViewPrescription'
import { getPrescriptions } from '../../Redux/Actions/user/userAll'
import { useReactToPrint } from "react-to-print";
import { startstopLoading } from '../../Redux/Reducers/globalSlice';



export default function Prescription() {
  const [showModal, setShowModal] = useState(false);
  const [viewP, setViewP] = useState();
  const limit = 10;
  const PrescriptionRef = useRef();
  const handlePresPrint = useReactToPrint({
    content: () => PrescriptionRef.current,
  });
  // const Image_url = process.env.REACT_APP_IMG_URL + "public/uploads/";
  const dispatch = useDispatch();
  const [page, setPage] = useState("1");
  const [search, setSearch] = useState("");

  const prescriptions = useSelector((state) => state.consultaions.prescriptions);

  const handleClose = () => {
    setShowModal(false)
  }

  useEffect(() => {
    let data = {
      page, limit, search
    }
    dispatch(startstopLoading(true));
    dispatch(getPrescriptions(data)).then((res) => {
      if (res?.payload?.success == true || res?.payload?.success == false) {
        dispatch(startstopLoading(false));
      }
    });
  }, [page, limit, search]);

  const handlePageClick = (e) => {
    setPage(e?.selected + 1);
  };

  return (
    <Main>
      <div className="container-fluid py-3 h-100">
        <div className="row">
          <div className="col-lg-11 col-12 bg-white p-0 client">
            <div className="d-flex justify-content-between px-3 pt-3 align-items-center">
              <div className=" client-heading-div">
                <h2 className='common-properties-clients'>Prescriptions</h2>
              </div>
              <div className=" d-flex justify-content-between gap-5 align-items-center">
                <form className="" role="search">
                  <div className='search-div position-relative text-end'>
                    <input className='search-img ms-3 position-absolute top-50 translate-middle ' type="image" src={searchImg} alt="pic not found" />
                    <input className=" search w-75 py-2" type="search" onChange={(e) => setSearch(e.target.value)} placeholder="Search" aria-label="Search" />

                  </div>
                </form>
                <Link to='/prescription/add'>
                  <button type="submit" className=' new-pres'>New Prescription</button>
                </Link>
              </div>
            </div>
            <div className="main-content-client py-3">

              <Table className='' responsive>
                <thead className='my-2'>
                  <tr className=''>
                    <th>Issue Date</th>
                    <th>Client Name</th>
                    <th>Email Address</th>
                    <th>Item</th>
                    <th>Quantity</th>
                    <th></th>
                  </tr>
                </thead>
                {prescriptions?.data?.length ?
                  <tbody className=''>
                    {prescriptions?.data?.map((res, index) => (
                      <tr key={index} className='table-row '>

                        <td>{formatDateToDMY(res?.date)}</td>
                        <td className='text-capitalize'>{res?.clientDetails.fullName}</td>
                        <td>{res?.clientDetails.email}</td>
                        <td className=''>
                          {res.medication.map((medsRes) =>
                            <p>
                              {medsRes?.meds}
                            </p>
                          )
                          }
                        </td>
                        <td className=''>
                          {res.medication.map((qnty) =>
                            <p>
                              {qnty?.quantity}
                            </p>
                          )
                          }
                        </td>
                        <td><button onClick={() => {
                          setShowModal(true)
                          setViewP(res)
                        }} className='table_link_ text-decoration-underline text-color border-0 bg-transparent '>View</button></td>

                      </tr>
                    ))
                    }
                  </tbody>
                  :
                  <>
                    <caption className="text-center">
                      No Data
                    </caption>
                  </>
                }
                {showModal &&
                  <ViewPrescription
                    ref={PrescriptionRef}
                    handleClose={handleClose}
                    viewPresData={viewP}
                    handlePrint={handlePresPrint}
                  />
                }
              </Table>
              {prescriptions?.pagination?.total > 10 && <div className="pagination_new d-flex justify-content-center">
                {/* <ReactPaginate
                  activeClassName="active"
                  nextLabel="Next >"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  pageCount={prescriptions?.pagination?.total / limit}
                  // pageCount={100 / limit}
                  previousLabel="< Prev"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"

                /> */}
                <div className="justify-content-center pagination_inner mt-1">
                  <ReactPaginate
                    className="pagination"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    pageCount={Math.ceil(prescriptions?.pagination?.total / limit)}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                </div>
              </div>}
            </div>
          </div>
        </div>
      </div>

    </Main>
  )
}