// Doctor side Video Call
import React, { useState, useRef, useEffect } from "react";
import Peer from "simple-peer";
import { toastInfo } from "../../Redux/Reducers/admin/adminPanelSlice";
import moment from "moment/moment";
import { Button, Modal } from "react-bootstrap";

const VideoCall = ({ me, consInfo, socket, onlineUsers, modalFunc }) => {
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState();
  const [name, setName] = useState("James Pearlman");
  const [call, setCall] = useState({});
  const [userId, setUserId] = useState();
  const [showModal, setShowModal] = useState(false);

  const myVideo = useRef(null);
  const userVideo = useRef(null);
  const connectionRef = useRef();

  useEffect(() => {
    if (navigator) {
      if (navigator) {
        navigator?.mediaDevices
          ?.getUserMedia({ video: true, audio: true })
          .then((currentStream) => {
            setStream(currentStream);
            myVideo.current.srcObject = currentStream;
          });
      }

        // if (navigator?.mediaDevices && navigator?.mediaDevices.getUserMedia) {
        //   navigator?.mediaDevices
        //     .getUserMedia({ video: true, audio: true })
        //     .then((currentStream) => {
        //       setStream(currentStream);
      
        //       // Check if myVideo.current is defined before accessing it
        //       if (myVideo.current) {
        //         myVideo.current.srcObject = currentStream;
        //       }
        //     })
        //     .catch((error) => {
        //       console.error("Error accessing media devices:", error);
        //     });
    
  

      // navigator.mediaDevices
      //   .getUserMedia({ video: true, audio: true })
      //   .then((currentStream) => {
      //     console.log("Current Stream: ", currentStream);

      //     const videoTracks = currentStream.getVideoTracks();
      //     console.log("Video Tracks: ", videoTracks);

      //     const audioTracks = currentStream.getAudioTracks();
      //     console.log("Audio Tracks: ", audioTracks);

      //     if (videoTracks.length > 0) {
      //       console.log("Video stream is active");
      //     } else {
      //       console.log("No video tracks available");
      //     }

      //     if (audioTracks.length > 0) {
      //       console.log("Audio stream is active");
      //     } else {
      //       console.log("No audio tracks available");
      //     }

      //     setStream(currentStream);
      //     myVideo.current.srcObject = currentStream;
      //   })
      //   .catch((error) => {
      //     console.error("Error accessing media devices:", error);
      //   });
    } else {
      console.warn("getUserMedia is not supported on this browser.");
    }
    // Listen for incoming calls
    socket.on("call_User", ({ from, name: callerName, signal }) => {
      setCall({ isReceivingCall: true, from, name: callerName, signal });
    });

    socket.on("user-left", () => {
      toastInfo("User left the Call");
      modalFunc();
      setCallEnded(true);
      setTimeout(() => {
        //  window.location.reload();   // need to remove this
      }, 2000);
    });
    // =================================================================

    const handleCustomerLate = (res) => {
      toastInfo("Customer is marked late and no refund will be provided");
    }

    socket.on("customer_late", (data) => handleCustomerLate(data));

    // Clean up the socket listener when component unmounts or socket changes
    // return () => {
    //   socket.off("customer_late", handleCustomerLate);
    // };
    // ======================================================================
    return () => {
      if (stream) {
        stream.getTracks().forEach((track) => track.stop());
      }

      window.location.reload();
    }
  }, [socket]);

  useEffect(() => {
    if (userId) {
      const isUser = onlineUsers?.find(
        (item) => item.userId == consInfo?.user_id?._id
      );
    }
  }, [onlineUsers]);

  useEffect(() => {
    if (consInfo && onlineUsers) {
      const socketId = onlineUsers?.find(
        (item) => item.userId == consInfo?.user_id?._id
      )?.socketId;
      if (socketId) {
        console.log(socketId, "233333333");
        setUserId(socketId);
      }
    }
  }, [consInfo, onlineUsers]);

  const callUser = (id) => {
    const peer = new Peer({
      initiator: true,
      trickle: false,
      stream,
      compatibilityMode: true,
    });
    peer.on("signal", (data) => {
      socket.emit("callUser", {
        userToCall: id,
        signalData: data,
        from: me,
        name,
      });
    });

    peer.on("stream", (currentStream) => {
      if (userVideo.current) {
        userVideo.current.srcObject = currentStream;
      }
    });
    // =========================================================================================
    // peer.on("track", (track) => {
    //   if (userVideo.current)
    //     userVideo.current.srcObject = new MediaStream([track]);
    // });

    peer.on("track", (track,  ) => {
      if (userVideo.current) {
        const mediaStream = userVideo.current.srcObject || new MediaStream();
        mediaStream.addTrack(track);
        userVideo.current.srcObject = mediaStream;
      }
    });
    // =========================================================================================

    peer.on("error", (err) => {
      console.error("Peer connection error", err);
    });

    socket.on("callAccepted", (signal) => {
      setCallAccepted(true);
      peer.signal(signal);
    });
    connectionRef.current = peer;
  };

  const leaveCall = () => {
    socket.emit("doctor-disconnected", {
      to: userId,
      consultaionId: consInfo?._id,
    });

    fetch("https://toothaid-backend.bosselt.com/api/pay/complete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ consultationId: consInfo?._id }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Payment complete:", data);
      })
      .catch((error) => {
        console.error("Error completing payment:", error);
      });

    setCallEnded(true);
    setCallAccepted(false);
    if (stream) stream.getTracks().forEach((track) => track.stop());
    if (connectionRef.current) {
      connectionRef.current.destroy();
    }
    window.location.reload();
  };

  const handleLeaveCallClick = () => {
    setShowModal(true);
  };

  const confirmLeaveCall = () => {
    setShowModal(false);
    leaveCall();
  };

  const cancelLeaveCall = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className={`d-flex justify-content-end mb-3 ${callAccepted && !callEnded ? "" : "d-none"}`}>
          <button
            className="btn btn-primary white-btn small_white_btn "
            // onClick={leaveCall}
            onClick={handleLeaveCallClick}
          >
            Leave Call 
          </button>
      </div>
      <div className="card mb-4 video_call_section">
        <div className="pb-0">
          <div className="consulatation_card">
            {callAccepted && !callEnded ? (
              <video
                playsInline
                width="626"
                height="323"
                ref={userVideo}
                autoPlay
                className="full-video"
              />
            ) : (
              <img
                src={require("../../Assets/Images/lg_video_frame.png")}
                alt="live_chat"
                className="img-fluid large_img"
              />
            )}

            <img
              src={require("../../Assets/Images/small_video_frame.png")}
              alt="live_chat"
              className="img-fluid video_call_frame"
            />

            <video
              playsInline
              muted
              ref={myVideo}
              autoPlay
              className="doctor-video"
            />

            {userId && !callAccepted && (
              <div className="start_call">
                <button
                  onClick={() => userId && callUser(userId)}
                  className="cre_new"
                >
                  Start Call
                </button>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Modal for Leave Call Confirmation */}
      <Modal show={showModal} onHide={cancelLeaveCall}>
        <Modal.Header className="justify-content-center">
          <Modal.Title className="text-center">Confirm Leave Call</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center mt-4 mb-4">
            Are you sure you want to cancel the call?
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <div className="d-flex justify-content-center gap-2">
            <Button variant="secondary" onClick={cancelLeaveCall} className="">
              Cancel
            </Button>
            <Button
              variant="danger"
              onClick={confirmLeaveCall}
              className="launch-btn"
            >
              Leave Call
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default VideoCall;
