// // import '../Medical History/medication_history.css'
// import Main from '../Main/Main'
// import arrowdown from '../../Assets/Icons/arrowdown.svg'
// import { Link } from 'react-router-dom'
// import React from "react";
// import { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { getContactPref, saveContactPref } from '../../Redux/Actions/user/userAll';



// export default function ContactDetails() {

//   const [contactInput, setContactInput] = useState({
//     recieve_offers: "",
//     recieve_confirmation: "",
//     recieve_reminders: "",
//   });
//   const dispatch = useDispatch();
//   const contactPref = useSelector((state) => state.consultaions.contactPref);
//   useEffect(() => {
//     dispatch(getContactPref());
//   }, []);

//   useEffect(() => {
//     if (contactPref) {
//       setContactInput(contactPref);
//     }
//   }, [contactPref]);

//   const handleClick = (ques, ans) => {
//     setContactInput((prev) => {
//       return {
//         ...prev,
//         [ques]: ans,
//       };
//     });
//   };

//   const updateContactPrefs = () => {
//     dispatch(saveContactPref(contactInput));
//   };
//   return (
//     <Main>
//       <div className="container medical_history">
//         <div className="col-lg-10">
//           <div className="desc_area pb-0 ">
//             <div className="row">
//               <div className="col-lg-12 p-0">
//                 <div className="back-button mb-4">
//                   <Link to="/myaccount" className="white-btn">
//                     Back
//                   </Link>
//                 </div>
//               </div>
//             </div>
//             <div className="row">
//               <div className="col-xl-12 col-lg-7 col-md-9 col-12 p-0">
//                 <div className="new_consultation_booking common_shadow bg-white">
//                   <h2 className="common_title padding_36">Contact Preferences</h2>

//                   <div className="card">
//                     <div className="card-body inner_padding personal_info_inner">

//                       <div className="d-flex justify-content-between align-items-center mb-3 peronal-content-detail">

//                         <h6 className="mb-0 w-100">
//                           Recieve offers and new information about ToothAid
//                         </h6>
//                         <div className="options_part questions_options">
//                           <ul className="mb-0 d-flex justify-content-end gap-3 p-0">
//                             <li>
//                               <a
//                                 style={{cursor: "pointer"}}
//                                 onClick={() =>
//                                   handleClick("recieve_offers", "1")
//                                 }
//                                 className={
//                                   contactPref
//                                     ? contactInput?.recieve_offers == "1"
//                                       ? "select_option_btn  box_shadow selected_color"
//                                       : "select_option_btn  box_shadow"
//                                     : "select_option_btn  box_shadow"
//                                 }
//                               >
//                                 Yes
//                               </a>
//                             </li>
//                             <li>
//                               <a
//                                style={{cursor: "pointer"}}
//                                 onClick={() =>
//                                   handleClick("recieve_offers", "0")
//                                 }
//                                 className={
//                                   contactPref
//                                     ? contactInput?.recieve_offers == "0"
//                                       ? "select_option_btn  box_shadow selected_color"
//                                       : "select_option_btn box_shadow"
//                                     : "select_option_btn box_shadow"
//                                 }
//                               >
//                                 No
//                               </a>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                       <div className="d-flex justify-content-between align-items-center mb-3  peronal-content-detail">
//                         <h6 className="mb-0 w-100">
//                           Recieve appointment confirmation
//                         </h6>
//                         <div className="options_part questions_options">
//                           <ul className="mb-0 d-flex justify-content-end gap-3 p-0">
//                             <li>
//                               <a
//                                style={{cursor: "pointer"}}
//                                 onClick={() =>
//                                   handleClick("recieve_confirmation", "1")
//                                 }
//                                 className={
//                                   contactPref
//                                     ? contactInput?.recieve_confirmation ==
//                                       "1"
//                                       ? "select_option_btn box_shadow selected_color"
//                                       : "select_option_btn  box_shadow"
//                                     : "select_option_btn  box_shadow"
//                                 }
//                               >
//                                 Yes
//                               </a>
//                             </li>
//                             <li>
//                               <a
//                                style={{cursor: "pointer"}}
//                                 onClick={() =>
//                                   handleClick("recieve_confirmation", "0")
//                                 }
//                                 className={
//                                   contactPref
//                                     ? contactInput?.recieve_confirmation ==
//                                       "0"
//                                       ? "select_option_btn  box_shadow selected_color"
//                                       : "select_option_btn box_shadow"
//                                     : "select_option_btn  box_shadow"
//                                 }
//                               >
//                                 No
//                               </a>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                       <div className="d-flex justify-content-between align-items-center mb-3  peronal-content-detail">
//                         <h6 className="mb-0 w-100">
//                           Recieve appointment reminders
//                         </h6>
//                         <div className="options_part questions_options">
//                           <ul className="mb-0 d-flex justify-content-end gap-3 p-0">
//                             <li>
//                               <a
//                                style={{cursor: "pointer"}}
//                                 onClick={() =>
//                                   handleClick("recieve_reminders", "1")
//                                 }
//                                 className={
//                                   contactPref
//                                     ? contactInput?.recieve_reminders ==
//                                       "1"
//                                       ? "select_option_btn box_shadow selected_color"
//                                       : "select_option_btn  box_shadow"
//                                     : "select_option_btn  box_shadow"
//                                 }
//                               >
//                                 Yes
//                               </a>
//                             </li>
//                             <li>
//                               <a
//                                style={{cursor: "pointer"}}
//                                 onClick={() =>
//                                   handleClick("recieve_reminders", "0")
//                                 }
//                                 className={
//                                   contactPref
//                                     ? contactInput?.recieve_reminders ==
//                                       "0"
//                                       ? "select_option_btn  box_shadow selected_color"
//                                       : "select_option_btn box_shadow"
//                                     : "select_option_btn  box_shadow"
//                                 }
//                               >
//                                 No
//                               </a>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                       <div className='text-end p-2 py-3 '>
//                         <button className='btn update_btn text-white p-2' onClick={updateContactPrefs}>Update</button>
//                       </div>
//                     </div>
//                   </div>
//                 </div>

//               </div>
//             </div>
//           </div>
//         </div>
//       </div>

//     </Main>
//   )
// }



// // import '../Medical History/medication_history.css'
 import Main from '../Main/Main'
 import arrowdown from '../../Assets/Icons/arrowdown.svg'
 import { Link } from 'react-router-dom'
 import React from "react";
 import { useEffect, useState } from "react";
 import { useDispatch, useSelector } from "react-redux";
 import { getContactPref, saveContactPref } from '../../Redux/Actions/user/userAll';

export default function ContactDetails() {

  const [contactInput, setContactInput] = useState({
    recieve_offers: "",
    recieve_confirmation: "",
    recieve_reminders: "",
  });
  const dispatch = useDispatch();
  const contactPref = useSelector((state) => state.consultaions.contactPref);
  
  useEffect(() => {
    dispatch(getContactPref());
  }, []);

  useEffect(() => {
    if (contactPref) {
      setContactInput(contactPref);
    }
  }, [contactPref]);

  const handleClick = (ques, ans) => {
    setContactInput((prev) => ({
      ...prev,
      [ques]: ans,
    }));
  };

  const updateContactPrefs = () => {
    dispatch(saveContactPref(contactInput));
  };

  return (
    <Main>
         <div className="desc_area pb-0 ">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="back-button mb-4">
                  <Link to="/myaccount" className="white-btn">Back</Link>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xl-9 col-lg-7 col-md-9 col-12 ">
                <div className="new_consultation_booking common_shadow bg-white min-h-100">
                  <h2 className="common_title padding_36">Contact Preferences</h2>

                  <div className="card border-none">
                    <div className="card-body border-none inner_padding personal_info_inner">
                      <div className="d-flex justify-content-between align-items-center mb-3 peronal-content-detail">
                        <h6 className="mb-0 w-100">
                          Receive offers and new information about ToothAid
                        </h6>
                        <div className="options_part questions_options">
                          <ul className="mb-0 d-flex justify-content-end gap-3 p-0">
                            <li>
                              <a
                                style={{cursor: "pointer"}}
                                onClick={() => handleClick("recieve_offers", "1")}
                                className={`select_option_btn box_shadow ${contactInput.recieve_offers === "1" ? "selected_color" : ""}`}
                              >
                                Yes
                              </a>
                            </li>
                            <li>
                              <a
                                style={{cursor: "pointer"}}
                                onClick={() => handleClick("recieve_offers", "0")}
                                className={`select_option_btn box_shadow ${contactInput.recieve_offers === "0" ? "selected_color" : ""}`}
                              >
                                No
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      
                      <div className="d-flex justify-content-between align-items-center mb-3 peronal-content-detail">
                        <h6 className="mb-0 w-100">
                          Receive appointment confirmation
                        </h6>
                        <div className="options_part questions_options">
                          <ul className="mb-0 d-flex justify-content-end gap-3 p-0">
                            <li>
                              <a
                                style={{cursor: "pointer"}}
                                onClick={() => handleClick("recieve_confirmation", "1")}
                                className={`select_option_btn box_shadow ${contactInput.recieve_confirmation === "1" ? "selected_color" : ""}`}
                              >
                                Yes
                              </a>
                            </li>
                            <li>
                              <a
                                style={{cursor: "pointer"}}
                                onClick={() => handleClick("recieve_confirmation", "0")}
                                className={`select_option_btn box_shadow ${contactInput.recieve_confirmation === "0" ? "selected_color" : ""}`}
                              >
                                No
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      
                      <div className="d-flex justify-content-between align-items-center mb-3 peronal-content-detail">
                        <h6 className="mb-0 w-100">
                          Receive appointment reminders
                        </h6>
                        <div className="options_part questions_options">
                          <ul className="mb-0 d-flex justify-content-end gap-3 p-0">
                            <li>
                              <a
                                style={{cursor: "pointer"}}
                                onClick={() => handleClick("recieve_reminders", "1")}
                                className={`select_option_btn box_shadow ${contactInput.recieve_reminders === "1" ? "selected_color" : ""}`}
                              >
                                Yes
                              </a>
                            </li>
                            <li>
                              <a
                                style={{cursor: "pointer"}}
                                onClick={() => handleClick("recieve_reminders", "0")}
                                className={`select_option_btn box_shadow ${contactInput.recieve_reminders === "0" ? "selected_color" : ""}`}
                              >
                                No
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      
                      <div className='text-end p-2 py-3'>
                        <button className='btn update_btn text-white p-2' onClick={updateContactPrefs}>Update</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
    </Main>
  );
}
