import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../service/Api";
import { logOut } from "../../Reducers/admin/adminPanelSlice";

export const userAddConsultations = createAsyncThunk(
  "user/addConsultation",
  async (data, thunkAPI) => {
    try {
      const response = await api.post("/user/addConsultation", data);
      return { user: response.data };
    } catch (error) { }
  }
);

export const getConsultationDate = createAsyncThunk(
  "getConsultationDate",
  async (data, thunkApi) => {
    const response = await api.get(`user/getConsultations`, data);
    return { user: response.data };
  }
);
export const getPresriptions = createAsyncThunk(
  "getPresriptions",
  async (data, thunkApi) => {
    const response = await api.get(`user/getPrescriptions`, data);
    return { user: response };
  }
);

export const getAssistance = createAsyncThunk(
  "getAssistance",
  async (thunkAPI) => {
    const response = await api.get("user/needAssist");
    return { user: response.data };
  }
);
export const addMedicalHistory = createAsyncThunk(
  "addMedicalHistory",
  async (data, thunkApi) => {
    const response = await api.post("user/saveMedHistory", data);
    return { user: response.data };
  }
);

export const getMedicalHistory = createAsyncThunk(
  "getMedicalHistory",
  async (thunkApi) => {
    const response = await api.get("user/getMedHistory");
    return response.data;
  }
);
export const getDashboardHistory = createAsyncThunk(
  "dentistDashboard",
  async (thunkApi) => {
    const response = await api.get("dentistDashboard");
    return response.data;
  }
);

export const getClientList = createAsyncThunk(
  "getClientList",
  async (data) => {
    const response = await api.get(
      `/Myclientslist?page=${data?.page || 1}&limit=${data?.limit}${data?.search ? `&search=${data?.search}` : ''}${data?.date ? `&date=${data?.date}` : ''}`
    );
    return response.data;
  }
);
export const getPrescriptions = createAsyncThunk(
  "getPrescriptions",
  async (data) => {
    const response = await api.get(
      `/getdentistPrescriptions?page=${data?.page || 1}&limit=${data?.limit}${data?.search && `&search=${data?.search}${data?.id && `&id=${data?.id}`}`}`
    );
    return response.data;
  }
);
export const createPrescription = createAsyncThunk(
  "createPrescription",
  async (data) => {
    const response = await api.post(
      `/createdentistPrescription`, data
    );
    return response.data;
  }
);
export const getTotalEarnings = createAsyncThunk(
  "getTotalEarnings",
  async (data) => {
    const response = await api.get(
      `/getTotalEarnings?page=${data?.page || 1}&limit=${data?.limit}${data?.search && `&search=${data?.search}`}`
    );
    return response.data;
  }
);
export const getBookings = createAsyncThunk(
  "getBookings",
  async (data) => {
    const response = await api.get(
      `/getConfirmConsultations?page=${(data?.page - 1) || 0}&limit=${data?.limit}${data?.date && `&date=${data?.date}`}`
    );
    return response.data;
  }
);
export const getLeads = createAsyncThunk(
  "getLeads",
  async (data) => {
    const response = await api.get(
      `/getConsultations?page=${(data?.page - 1) || 0}&limit=${data?.limit}${data?.date && `&date=${data?.date}`}`
    );
    return response.data;
  }
);
export const getClientDetails = createAsyncThunk(
  "getClientDetails",
  async (id) => {
    const response = await api.get(
      `/memberDetailsdentist/${id}`
    );
    return response.data;
  }
);

export const addMedications = createAsyncThunk(
  "addMedication",
  async (data, thunkAPI) => {
    const response = await api.post("user/saveMedication", data);
    return { user: response.data };
  }
);
export const profileUpload = createAsyncThunk(
  "profileUpload",
  async (data, thunkAPI) => {
    const response = await api.post("saveDentistProfilePic", data);
    return { user: response.data };
  }
);
export const updateProfile = createAsyncThunk(
  "updateProfile",
  async (data, thunkAPI) => {
    const response = await api.put("updatedentistProfile", data);
    return { user: response.data };
  }
);

export const uploadConsNotesImage = createAsyncThunk(
  "uploadConsNotesImage",
  async (formData, thunkAPI) => {
    const { data } = await api.post("user/uploadConsNotesImage", formData);
    return data;
  }
);

export const getProfilePic = createAsyncThunk(
  "getProfilePic",
  async (thunkAPI) => {
    const response = await api.get("/getdentistInfo");
    return response.data;
  }
);
export const rescheduleConsultations = createAsyncThunk(
  "rescheduleConsultaions",
  async (data, thunkAPI) => {
    const response = await api.put("user/rescheduleConsultation", data);
    return response.data;
  }
);
export const getMedication = createAsyncThunk(
  "getMedication",
  async (thunkAPI) => {
    const response = await api.get("user/getMedication");
    return response.data;
  }
);

export const cancelConsultation = createAsyncThunk(
  "cancelConsultation",
  async (id, thunkApi) => {
    const response = await api.delete(`user/delConsultation/${id}`);
    return response.data;
  }
);

export const getPersonalInfo = createAsyncThunk(
  "getPersonalInfo",
  async (thunkAPI) => {
    const response = await api.get("getdentistInfo");
    return response.data;
  }
);

export const getBlogs = createAsyncThunk("getBlogs", async (thunkApi) => {
  const response = await api.get("user/getBlogs");
  return response.data;
});

export const updateConsNotes = createAsyncThunk(
  "updateConsNotes",
  async (data, thunkApi) => {
    const response = await api.post("user/updateNotes", data);
    return response.data;
  }
);

export const viewBlog = createAsyncThunk("viewBlog", async (id, thunkApi) => {
  const response = await api.get(`user/viewBlog/${id}`);
  return response.data;
});
export const confirmBooking = createAsyncThunk("acceptConsultation", async (data, thunkApi) => {
  const response = await api.post(`acceptConsultation`, {consultation_id : data.id });
  return response.data;
});

export const getUserConsInfo = createAsyncThunk(
  "getConsInfo",
  async (id, thunkApi) => {
    const response = await api.get(`user/getUserInfo/${id}`);
    return response.data;
  }
);

export const getAvailability = createAsyncThunk(
  "getAvailability",
  async (thunkApi) => {
    const response = await api.get("user/getAvail");
    return response.data;
  }
);

export const getWebsiteBlogs = createAsyncThunk(
  "getWebsiteBlogs",
  async (thunkApi) => {
    const response = await api.get("website/getBlogs");
    return response.data;
  }
);

export const getWebBlog = createAsyncThunk(
  "getWebBlog",
  async (id, thunkApi) => {
    const response = await api.get(`website/getBlog/${id}`);
    return response.data;
  }
);

export const getContactPref = createAsyncThunk(
  "getContactPref",
  async (thunkApi) => {
    const response = await api.get("getdentistContactPref");
    return response.data;
  }
);

export const saveContactPref = createAsyncThunk(
  "saveContactPref",
  async (data, thunkApi) => {
    const response = await api.post("savedentistContactPref", data);
    return response.data;
  }
);

export const addBecomeDoctor = createAsyncThunk(
  "addBecomeDoctor",
  async (data, thunkApi) => {
    const response = await api.post("user/addBecomeDoctor", data);
    return response.data;
  }
);
export const addReview =
  createAsyncThunk(
    "addReview",
    async (data) => {
      const response = await api.post("review", data);
      return response.data;
    }
  );
export const suspendAccount = async (data,) => {
  const response = await api.put("accountStatus", data);
  logOut()
  // return response.data;
}

export const deleteAccount = async () => {
  const response = await api.delete(`deleteDentist`);
  logOut()
  // return response.data;
}

// consultation get detail
export const getConsultationById = createAsyncThunk(
  "getConsultationById",
  async (id) => {
    const response = await api.get(
      `/getConsultationUserById/${id}`
    );
    return response.data;
  }
);

// cancel consultation 
export const deleteConsultation = createAsyncThunk(
  "deleteConsultation",
  async (id) => {
    const response = await api.put( `/removeconsultation/${id}`);
    return response.data;
  }
);


// Help and support

export const addHelpAndSupportIssue = createAsyncThunk(
  "addHelpAndSupportIssue",
  async (data, thunkAPI) => {
    const response = await api.post("createHelpSupport", data);
    return { user: response.data };
  }
);

// review consultation dentist
export const addDentistReviewtoConsultation = createAsyncThunk(
  "addDentistReviewtoConsultation",
  async (data) => {
    const response = await api.post( `/videocallreview`, data);
    return response.data;
  }
);