// import './signIn.css'
import logo from '../../Assets/Images/logo.png'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react';
import { useFormik } from "formik"
import Cookies from "universal-cookie";
import { useDispatch } from "react-redux";
import eye from '../../Assets/Icons/eye.svg'
import eyeHide from '../../Assets/Icons/eyehide.svg'
import { DentalLogin } from '../../Redux/Actions/DentalLogin';

export default function SignIn() {
  const [showPass, setShowPass] = useState(false);
  const [keepLogin, setKeepLogin] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const cookies = new Cookies();
  useEffect(() => {
    if (
      window.sessionStorage.getItem("adminToken") ||
      cookies.get("adminToken")
    ) {
      if (
        cookies.get("adminToken") &&
        !window.sessionStorage.getItem("adminToken")
      ) {
        window.sessionStorage.setItem("adminToken", cookies.get("adminToken"));
      }
      window.location.href = "/dashboard";
    }
  }, []);
  const validate = (values) => {
    const errors = {};
    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }
    if (!values.password) {
      errors.password = "Required"
    } else if (values.password.length < 8 || values.password.length > 10) {
      // errors.password = "Invalid Password";
    }
    return errors;
  }
  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
    },
    validate,
    onSubmit: values => {
      dispatch(DentalLogin(values, keepLogin))
    },
  });
  return (
    <>
      <div className="-signin-container container-fluid h-100 ">
        <div className="signin-col ">
          <div className="signin-row row justify-content-between">
            <div className="content-div col-md-6 col-sm-12">
              <div className="content-inner-div ">
                <div className="signin-div ">
                  <h2 className="signin-h2">Sign In</h2>
                  <h3 className="enter-h3">
                    Enter your email and password to sign in!
                  </h3>
                </div>
                <div className="form-div">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="email-div">
                      <div className='label-div'>
                        <label className='label' htmlFor="">Email*</label>
                      </div>
                      <div>
                        <input
                          type="text"
                          name="email"
                          className="input w-100"
                          id="username"
                          placeholder="mail@simmmple.com"
                          value={formik.values.email}
                          onChange={formik.handleChange}
                        />
                        {formik.errors.email && <p className="formik-error">{formik.errors.email}</p>}
                      </div>
                    </div>
                    <div className="password-div">
                      <div className='label-div'>
                        <label className='label' htmlFor="">Password*</label>
                      </div>
                      <div className='input-group'>
                        <input className='input w-100'
                          type={showPass ? "text" : "password"}
                          name="password"
                          id=""
                          placeholder='Min. 8 characters'
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          required />
                        {/* <div className='eye_img_div position-absolute'
                          onClick={() => setShowPass(!showPass)}
                        >
                          <img className='eye_img w-25 ' src={showPass ? eyeHide : eye} alt="" />
                        </div> */}
                      </div>
                    </div>
                    <div className="checkbox-forget-div">
                      <div className="checkbox-label-div">
                        <div className="checkbox-div">
                          <input className='checkbox' type="checkbox" name="" id="remind"
                            onChange={(e) => setKeepLogin(e.target.checked)}
                          />
                        </div>
                        <div className="label-div">
                          <label className='label loggedin-label' htmlFor="remind">Keep me logged in</label>
                        </div>
                      </div>
                      <div className="forget-div">
                        <Link className='forget-a label' to="/forget_password">Forget password?</Link>
                      </div>
                    </div>
                    <div className="signinbtn-div">
                      <button className='signin-btn label' type="submit">Sign In</button>
                    </div >
                  </form >
                </div >
              </div>
            </div >
            <div className="logo-div col-md-6 col-sm-12 h-100 ">
              <div className="logo-img-div ">
                <img className='logo-img' src={logo} alt="Pic not found!" />
              </div>
            </div>
          </div >
        </div >
      </div >
    </>
  )
}