import profile from "../../Assets/Images/defaultUser.jpg";
import Main from "../Main/Main";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  addReview,
  getDashboardHistory,
} from "../../Redux/Actions/user/userAll";
import ReactStars from "react-stars";
import { getImageUrl, options } from "../../Utils/commonFiles";
import { startstopLoading } from "../../Redux/Reducers/globalSlice";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const dispatch = useDispatch();
  const navigate = useNavigate()

  const profileDetails = useSelector((state) => state.consultaions.profilePic);
  const data = useSelector((state) => state.consultaions.dashboardData);

  const [state, setState] = useState({
    rating: 5,
    comment: "",
  });

  const ratingChanged = (newRating) => {
    setState({
      ...state,
      rating: newRating,
    });
  };

  const submitReview = async (e) => {
    e.preventDefault();
    try {
      await dispatch(addReview(state)).then(() => {
        let button = document.getElementById("close-modal");
        button.click();
      });
    } catch (err) {}
  };

  useEffect(() => {
    dispatch(startstopLoading(true));
    dispatch(getDashboardHistory()).then((res) => {
      if (res?.payload?.success == true || res?.payload?.success == false) {
        dispatch(startstopLoading(false));
      }
    });
  }, []);

  return (
    <Main>
      <div className="dashboard-container container-fluid p-0">
        <div className="dashboard-col">
          <div className="dashboard-row  col-xxl-11 col-xl-12 col-12">
            <div className="row">
              <div className="col-md-4">
                <div className="details-div same-property ">
                  <div className="heading-div p-sm-0">
                    <h2 className="details-h2 common-h2">My Details</h2>
                  </div>
                  <div className="detail-dontent-div detail-dontent-div1">
                    <div className="img-div">
                      <img
                        className="new-profile-image"
                        src={
                          profileDetails?.image
                            ? getImageUrl(profileDetails.image)
                            : profile
                        }
                        alt="Pic not found!"
                      />
                    </div>
                    <div className="detail-content-div">
                      <h3 className="accountname-h3 common-h3-h4">
                        {profileDetails?.firstName +
                          " " +
                          profileDetails?.lastName}
                      </h3>
                      <h4 className="dentist-h4 common-h3-h4 mt-2 text-grey mb-2">
                        Dentist
                      </h4>
                      <h4 className="join-h4 common-h3-h4 dentist-h4 text-grey">
                        Joined{" "}
                        {new Date(profileDetails?.createdAt).toLocaleDateString(
                          "en-US",
                          options
                        )}
                      </h4>
                    </div>
                  </div>
                  <div className=" detail-dontent-div2">
                    <h3 className="accountname-h3 common-h3-h4 dentist-h4">
                      This month
                    </h3>
                    <div className="bookings-earnings-div">
                      <div className="div1 common-div">Bookings:</div>
                      <div className="div2 common-div">
                        {data?.totalBookingsThisMonth}
                      </div>
                      <div className="div3 common-div">Earnings:</div>
                      <div className="div4 common-div">
                        £{data?.earningsThisMonth.toFixed(2)}
                      </div>
                    </div>
                  </div>
                  <div className=" detail-dontent-div2">
                    <h3 className="accountname-h3 common-h3-h4 dentist-h4">
                      All Time
                    </h3>
                    <div className="bookings-earnings-div">
                      <div className="div1 common-div">Bookings:</div>
                      <div className="div2 common-div">
                        {data?.totalBookingsAllTime}
                      </div>
                      <div className="div3 common-div">Earnings:</div>
                      <div className="div4 common-div">
                        £{data?.totalEarningsAllTime.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="help-div same-property mt-4">
                  <div className="heading-div p-sm-0">
                    <h2 className="help-h2 common-h2">Help</h2>
                  </div>
                  <div className="bookings-earnings-div helpline-div">
                    <div className="div1 common-div">Helpline:</div>
                    <div className="div2 common-div">020 3880 6050</div>
                    <div className="div3 common-div">Email:</div>
                    <div className="div4 common-div">help@toothaid.co</div>
                  </div>
                  <div className="review-div">
                    {/* <button
                      className="submit-btn common-div"
                      type="submit"
                      data-bs-toggle="modal"
                      data-bs-target="#exampleModal"
                    >
                      Submit a Review
                    </button> */}

                    <button
                      className="submit-btn common-div"
                      onClick={() => navigate("/helpandsupport")}
                    >
                      Submit an issue
                    </button>
                    <div
                      class="modal fade"
                      id="exampleModal"
                      tabindex="-1"
                      aria-labelledby="exampleModalLabel"
                      aria-hidden="true"
                    >
                      <div class="modal-dialog modal-dialog-centered">
                        <div class="modal-content">
                          <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">
                              Submit Review
                            </h5>
                            <button
                              type="button"
                              class="btn-close"
                              id="close-modal"
                              data-bs-dismiss="modal"
                              aria-label="Close"
                            ></button>
                          </div>
                          <form onSubmit={submitReview}>
                            <div class="modal-body text-center ">
                              <label className="">Rating:</label>
                              <div className="d-flex justify-content-center">
                                <ReactStars className="hola-amigo"
                                  count={5}
                                  onChange={ratingChanged}
                                  size={30}
                                  color2={"#ffd700"}
                                  half={false}
                                  // value={5}
                                  value={state.rating}
                                  sty
                                />
                              </div>
                              <textarea
                                name=""
                                id=""
                                rows={5}
                                cols={30}
                                required
                                onChange={(e) =>
                                  setState({
                                    ...state,
                                    comment: e.target.value,
                                  })
                                }
                                className="p-2 input_ lead fw-light fs-6"
                                placeholder="Leave Comment..."
                              ></textarea>
                            </div>
                            <div class="modal-footer">
                              {/* <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button> */}
                              <button
                                htmType="button"
                                class=" submit-btn fw-bold py-1 px-5 "
                              >
                                Submit
                              </button>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="request-div same-property">
                  <div className="heading-div p-sm-0">
                    <h2 className="request-h2 common-h2">New Requests</h2>
                  </div>
                  <div className="request-content-div">
                    <div className="booking-div">
                      <div className="circle">
                        <h3 className="h32-h3 common-div">
                          {data?.pendingBookings}
                        </h3>
                      </div>
                      <h4 className="pending-h4 common-div">
                        Pending Bookings
                      </h4>
                    </div>
                    <div className="earning-div detail-dontent-div2">
                      <h2 className="potential-h2 common-div">
                        {" "}
                        <span>£{data?.potentialEarnings} </span>Potential
                        Earnings
                      </h2>
                    </div>
                  </div>
                </div>

                <div className="clients-div same-property mt-4">
                  <div className="heading-div p-sm-0">
                    <h2 className="client-h2 common-h2">Clients</h2>
                  </div>
                  <div className="request-content-div">
                    <div className="booking-div">
                      <div className="circle">
                        <h3 className="h32-h3 common-div">
                          {data?.clientsRegistered}
                        </h3>
                      </div>
                      <h4 className="pending-h4 common-div">
                        Clients Registered
                      </h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-4">
                <div className="setting-div same-property h-100">
                  <div className="heading-div p-sm-0">
                    <h2 className="setting-h2 common-h2">Lead Settings</h2>
                  </div>
                  <div className="data-div">
                    <h2 className="data-h2">DATA TBD</h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
}