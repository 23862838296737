import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";
import RoutesPage from "./routes/Routes";
import Loader from "./Components/Loader";

function App() {
  return (
    <div className="App">
      <ToastContainer limit={1} />
      <Loader />
      <RoutesPage />
    </div>
  );
}
export default App; 
