import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";

const ReviewModal = ({
  show,
  handleClose,
  rating,
  handleRating,
  handleSubmit,
  setState,
  state,
}) => {
  return (
    <Modal show={show} onHide={handleClose} className="rating-main-popup">
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="rating-sec">
          <div className="row">
            <div className="col-lg-12">
              <h3>Rate us the quality of video call service</h3>
            </div>
            <div className="col-lg-12 d-flex justify-content-center star-pattern">
              <Rating onClick={handleRating} initialValue={rating} />
            </div>
            <div className="col-lg-12 mt-4">
              <h5>Review(Optional)</h5>
              <textarea
                rows={3}
                placeholder=""
                className="w-100"
                onChange={(e) =>
                  setState({
                    ...state,
                    video_call_comment: e.target.value,
                  })
                }
              ></textarea>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-center">
        <button variant="primary" className=" new-pres" onClick={handleSubmit}>
          Submit
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReviewModal;
