// import './myaccount.css'
import Main from '../Main/Main'
import { Link } from 'react-router-dom'

export default function Myaccount() {
  return (
    <Main>
      <div class="col-xl-9 col-12 ">
        <div class="new_consultation_booking common_shadow bg-white">
          <h2 class=" commom-prop common_title padding_36">My Account</h2>
          <div class="row p-0"><div class="col-12">
            <div class="personal_details personal_history_accordian px-3">
              <div class="card my-3"><div class="card-body p-0">
                <Link to='/personal_information' class="d-flex account_info">
                  <h6 class=" commom-prop mb-0">Personal Information</h6>
                  <p class="update-history small_para">View and Update Your Personal Information</p>
                </Link>
              </div>
              </div>
              <div class="card my-3">
                <div class="card-body p-0">
                  <Link to='/bank-detail' class="d-flex account_info" >
                    <h6 class=" commom-prop mb-0">Bank Details</h6>
                    <p class="update-history small_para">Update your bank details where we’ll pay your earnings</p>
                  </Link>
                </div>
              </div>
              <div class="card my-3"><div class="card-body p-0">
                <Link to='/contact_details' class="d-flex account_info" >
                  <h6 class=" commom-prop mb-0">Contact Settings</h6><p class="update-history small_para">Update your contact details and communication preferences</p>
                </Link>
              </div>
              </div>
              <div class="card my-3">
                <div class="card-body p-0">
                  <Link class="d-flex account_info" to="/account_settings">
                    <h6 class=" commom-prop mb-0">Account Settings</h6>
                    <p class="update-history small_para">Delete account</p>
                  </Link>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </Main>
  )
}