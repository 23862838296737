import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateProfile } from "../../Redux/Actions/user/userAll";
import { toast } from "react-toastify";

const Qualifications = ({ userData, from }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    uni_attended: "",
    highest_qualification: "",
    year_of_grad: "",
    gdc_no: "",
    dbs_no: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  useEffect(() => {
    if (userData) {
      setFormData((prevData) => ({
        uni_attended: userData.uni_attended || prevData.uni_attended,
        highest_qualification:
          userData.highest_qualification || prevData.highest_qualification,
        year_of_grad: userData.year_of_grad || prevData.year_of_grad,
        gdc_no: userData.gdc_no || prevData.gdc_no,
        dbs_no: userData.dbs_no || prevData.dbs_no,
      }));
    }
  }, [userData]);

  const handleUpdate = (e) => {
    const responseObj = { ...userData, ...formData };
    dispatch(updateProfile({ personalInfo: responseObj }))
      .then((res) => {
        if (res.payload.success) {
          toast.success("Profile updated successfully!");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="card rounded mt-3">
      <div className="card-header accordian_head" id="heading5">
        <h5 className="mb-0">
          <div
            className="d-flex personal_inner_sec dropdown_arrow collapsed"
            data-bs-toggle="collapse"
            data-bs-target="#collapse5"
            aria-expanded="true"
            aria-controls="collapse5"
            type="button"
          >
            <h6 className="mb-0 h6">Qualifications</h6>
          </div>
        </h5>
      </div>
      <div
        id="collapse5"
        className="collapse"
        aria-labelledby="heading5"
        data-parent="#accordion"
      >
        <div className="card-body inner_padding personal_info_inner">
          {[
            "uni_attended",
            "highest_qualification",
            "year_of_grad",
            "gdc_no",
            "dbs_no",
          ].map((field, index) => (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center mb-3 peronal-content-detail"
            >
              <p className="mb-0 w-100">
                {field === "year_of_grad"
                  ? "Year of Graduation"
                  : field === "uni_attended"
                  ? "University Attended"
                  : field
                      .replace("_", " ")
                      .replace(/\b\w/g, (c) => c.toUpperCase())}
              </p>
              <div className="options_part questions_options">
                <ul className="mb-0 d-flex justify-content-end p-0">
                  <li>
                    <input
                      className="select_option_btn width_200 box_shadow"
                      placeholder={
                        field === "year_of_grad"
                          ? "Year of Graduation"
                          : field === "uni_attended"
                          ? "University Attended"
                          : field
                              .replace("_", " ")
                              .replace(/\b\w/g, (c) => c.toUpperCase())
                      }
                      name={field}
                      value={formData[field]}
                      onChange={handleChange}
                      type="text"
                    />
                  </li>
                </ul>
              </div>
            </div>
          ))}
          <div className="add-more-btn mt-4 text-end">
            <button
              onClick={handleUpdate}
              className="update_btn text-white p-2 btn"
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Qualifications;
