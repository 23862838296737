import React, { useEffect, useState } from "react";
import Main from "../Main/Main";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getPersonalInfo } from "../../Redux/Actions/user/userAll";

const OnboardingDetail = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const userInfo = useSelector((state) => state?.consultaions?.userInfo);

  useEffect(() => {
    dispatch(getPersonalInfo());
  }, []);

  // const handleAddAccount = async () => {
  //   setLoading(true);
  //   setError("");
  //   setSuccess("");
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_HOST_NAME}api/pay/addDentistBankAccount`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ` + sessionStorage.getItem("adminToken"),
  //         },
  //         body: JSON.stringify({
  //           email: userInfo?.email,
  //         }),
  //       }
  //     );

  //     if (!response.ok) {
  //       throw new Error("Failed to add bank account");
  //     }

  //     const data = await response.json();

  //     if (data?.accountLink) {
  //       window.location.href = data.accountLink;
  //     } else {
  //       throw new Error("Account link not provided");
  //     }
  //   } catch (error) {
  //     setError(error.message || "Something went wrong");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleAddOrEditAccount = async () => {
    setLoading(true);
    setError("");
    setSuccess("");
    const bodyData = userInfo?.stripeAccountId
      ? { stripeAccountId: userInfo.stripeAccountId }
      : { email: userInfo?.email };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_HOST_NAME}api/pay/${
          userInfo?.stripeAccountId
            ? "editDentistBankAccount"
            : "addDentistBankAccount"
        }`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ` + sessionStorage.getItem("adminToken"),
          },
          body: JSON.stringify(bodyData),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to process account");
      }
      const accountData = await response.json();
      if (accountData?.accountLink) {
        window.location.href = accountData.accountLink; // Redirect user to the Stripe onboarding page
      } else {
        throw new Error("Account link not provided");
      }
    } catch (error) {
      setError(error.message || "Something went wrong");
    } finally {
      setLoading(false);
    }
  };
  console.log(userInfo, "userInfo");

  return (
    <Main>
      <div className="desc_area pb-0">
        <div className="row">
          <div className="col-lg-12">
            <div className="back-button mb-4">
              <Link to="/myaccount" className="white-btn">
                Back
              </Link>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-9 col-lg-7 col-md-9 col-12">
            <div className="new_consultation_booking common_shadow bg-white min-h-100">
              <h2 className="common_title padding_36">Bank Details</h2>

              <div className="card border-none">
                <div className="border-none card-body inner_padding personal_info_inner">
                  <div className="d-flex justify-content-between align-items-center mb-3 personal-content-detail">
                    <h6 className="mb-0 w-100">
                      {userInfo?.stripeAccountId
                        ? "You already have an account. Click here to manage it."
                        : "Don't have an account? Click here to create one."}
                    </h6>
                  </div>

                  <div className="text-end p-2 py-3">
                    {error && <div className="alert alert-danger">{error}</div>}
                    {success && (
                      <div className="alert alert-success">{success}</div>
                    )}
                    {/* <button
                          className="btn update_btn text-white p-2"
                          // onClick={handleAddAccount}
                          onClick={handleAddOrEditAccount}
                          disabled={loading}
                        >
                          {loading ? "Adding..." : "Add Account"}
                        </button> */}

                    <button
                      className="btn update_btn text-white p-2"
                      onClick={handleAddOrEditAccount}
                      disabled={loading}
                    >
                      {loading
                        ? "Processing..."
                        : userInfo?.stripeAccountId
                        ? "Edit Account"
                        : "Add Account"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
};

export default OnboardingDetail;
