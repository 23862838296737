import React from 'react'
import Header from '../Header/Header'
import Sidebar from '../Sidebar/Sidebar'
import './main.css'


export default function Main({children}) {
  return (
    <>
      <div className="layout-wrapper g-0">
        <header className='header'>
          <Header />
        </header>
        <div className="side-content-div row justify content-between mx-0">
          <aside className='col-12 col-xxl-2  col-xl-2 col-lg-3 collapse-lg p-0  '>
            <Sidebar />
          </aside>
          <div className="content overflow-auto col-12 col-xxl-10 col-xl-10 col-lg-9 main-content-layout">
            {children} 
          </div>
        </div>
      </div>
    </>
  )
}