import "../Clients/clients.css";
// import './earnings.css'
import Main from "../Main/Main";
import searchImg from "../../Assets/Icons/search.svg";
import { Table } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getTotalEarnings } from "../../Redux/Actions/user/userAll";
import { formatDateToDMY } from "../../Utils/commonFiles";
import { startstopLoading } from "../../Redux/Reducers/globalSlice";

export default function Earnings() {
  const limit = 10;
  const dispatch = useDispatch();
  const [page, setPage] = useState("1");
  const [search, setSearch] = useState("");

  const data = useSelector((state) => state.consultaions.myEarnings);

  useEffect(() => {
    let data = {
      page,
      limit,
      search,
    };
    dispatch(startstopLoading(true));
    dispatch(getTotalEarnings(data)).then((res) => {
      if (res?.payload?.success == true || res?.payload?.success == false) {
        dispatch(startstopLoading(false));
      }
    });
  }, [page, limit, search]);

  const handlePageClick = (e) => {
    setPage(e?.selected + 1);
  };

  const status = ["Pending", "Paid", "Cancelled by Dentist", "Available"];

  return (
    <Main>
      <div className="containe-fluid px-3 ">
        <div className="row gap-4">
          <div className="col-xl-8 bg-white ad-card-prop p-0">
            <div className="d-flex flex-row justify-content-between p-3">
              <div className=" client-heading-div">
                <h2 className="common-properties-clients">Earnings</h2>
              </div>
              {/* <div className=" ">
                <form className="" role="search">
                  <div className='search-div position-relative'>
                    <input className='search-img ms-4 position-absolute top-50 translate-middle' type="image" src={searchImg} alt="pic not found" />
                    <input className=" search form-control ps-5 me-2" type="search" onChange={(e) => setSearch(e.target.value)} placeholder="Search" aria-label="Search" />
                  </div>
                </form>
              </div> */}
              <div className=" ">
                <form className="" role="search">
                  <div className="search-div position-relative text-end">
                    <input
                      className="search-img ms-4 position-absolute top-50 translate-middle "
                      type="image"
                      src={searchImg}
                      alt="pic not found"
                    />
                    <input
                      className=" search w-75 py-2"
                      type="search"
                      onChange={(e) => setSearch(e.target.value)}
                      placeholder="Search"
                      aria-label="Search"
                    />
                  </div>
                </form>
              </div>
            </div>
            <div className="main-content-client  ">
              <Table className="dealer-table-c" responsive>
                <thead className="my-2 px-4 ">
                  <tr className="">
                    <th>Consultation Date</th>
                    <th>Client Name</th>
                    <th>Description</th>
                    <th>Earnings</th>
                    <th>Status</th>
                  </tr>
                </thead>
                {data?.consultations?.length ? (
                  <tbody>
                    {data?.consultations?.map((client, index) => (
                      <tr key={index} className="table-row">
                        <td>{formatDateToDMY(client.date)}</td>
                        <td className="text-capitalize">
                          {client?.user_id.firstName +
                            " " +
                            client?.user_id.lastName}
                        </td>
                        <td>{client.description || "Online Consultation"}</td>
                        <td>{client?.price}</td>
                        <td>{status[client?.isaccepted]}</td>
                      </tr>
                    ))}
                  </tbody>
                ) : (
                  <>
                    <caption className="text-center">No Data</caption>
                  </>
                )}
              </Table>
            </div>
            {data?.total > limit  && (
              <div className="d-flex justify-content-center">
                <div className="justify-content-center pagination_inner mt-1">
                  <ReactPaginate
                    className="pagination"
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                    pageCount={Math.ceil(data?.total / limit)}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                  />
                </div>
                {/* <div className='pagination_new text-center '>
                <ReactPaginate
                  activeClassName="active"
                  nextLabel="Next"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={1}
                  pageCount={data?.total / limit}
                  // pageCount={100/ limit}
                  previousLabel="Prev"
                  renderOnZeroPageCount={null}
                  containerClassName="pagination"
                  pageClassName="page-item"
                  pageLinkClassName="page-link"
                  previousClassName="page-item"
                  previousLinkClassName="page-link"
                  nextClassName="page-item"
                  nextLinkClassName="page-link"
                  breakLabel="..."
                  breakClassName="page-item"
                  breakLinkClassName="page-link"
                />
              </div> */}
              </div>
            )}
          </div>
          <div className="col-xl-3 side-card-div bg-white ad-card-prop p-0">
            <div className="side-card p-xxl-4 p-lg-4 p-xl-2 p-4 d-flex justify-content-between">
              <div>
                <h2 className="common-h2-p">Available</h2>
                <p className="common-h2-p">£{data?.available.toFixed(2)}</p>
              </div>

              <button type="submit" className="withdraw-btn">
                Withdraw
              </button>
            </div>
            <div className="side-card p-xxl-4 p-lg-4 p-xl-2 p-4 ">
              <h2 className="common-h2-p">Pending</h2>
              <p className="common-h2-p">£{data?.pending.toFixed(2) || 0}</p>
            </div>
            <div className="side-card p-xxl-4 p-lg-4 p-xl-2 p-4 ">
              <h2 className="common-h2-p">Withdrawn</h2>
              <p className="common-h2-p">£{data?.withdrawn.toFixed(2) || 0}</p>
            </div>
            <div className="side-card p-xxl-4 p-lg-4 p-xl-2 p-4 ">
              <h2 className="common-h2-p">Total Earnings</h2>
              <p className="common-h2-p">£{data?.totalearnings.toFixed(2)}</p>
            </div>
          </div>
        </div>
      </div>
    </Main>
  );
}
