import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import Dashboard from "../Components/Dashboard/Dashboard";
import Bookings from "../Components/Bookings/Bookings";
import Leads from "../Components/Leads/Leads";
import Clients from "../Components/Clients/Clients";
import Earnings from "../Components/Earnings/Earnings";
import Myaccount from "../Components/My Account/Myaccount";
import Members from "../Components/Members/Members";
import SignIn from "../Components/SignIn/SignIn";
import PrivateRoute from "./PrivateRoute";
import ChangePassword from "../Components/Change Password/ChangePassword";
// import MedicalHistory from "../Components/Medical History/MedicalHistory";
import PersonalInfo from "../Components/Personal Information/PersonalInfo";
import ContactDetails from "../Components/Contact Details/ContactDetails";
import Prescription from "../Components/Prescriptions/Prescription";
import AccountSettings from "../Components/Account Settings/AccountSeetings";
import AddPrescription from "../Components/AddPrescriptions/AddPrescription";
import ForgetPassword from '../Components/Forget Password/ForgetPassword'
import ConsultationWEBRTC from "../Components/Consultations/ConsultationWEBRTC";
import OnboardingDetail from "../Components/Bank Detail/OnboardingDetail";
import HelpSupport from "../Components/Help And Support/HelpSupport";

const RoutesPage = () => {
  return (
    <>
      <Router>
        <Routes>
          <Route element={<PrivateRoute />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/leads" element={<Leads />} />
            <Route path="/clients" element={<Clients />} />
            <Route path="/earnings" element={<Earnings />} />
            {/* <Route path="/prescriptions" element={<Dashboard />} /> */}
            <Route path="/myaccount" element={<Myaccount />} />
            <Route path="/client/:id" element={<Members />} />
            {/* <Route exact path="/medical_history" element={<MedicalHistory />} /> */}
            <Route exact path="/personal_information" element={<PersonalInfo />} />
            <Route exact path="/bank-detail" element={<OnboardingDetail />} />
            <Route exact path="/contact_details" element={<ContactDetails />} />
            <Route path="/helpandsupport" element={<HelpSupport />} />
            <Route exact path="consultation/:id" element={<ConsultationWEBRTC />}/>
          </Route>
          <Route exact path="/" element={<SignIn />} />
          <Route exact path="/forget_password" element={<ForgetPassword />} />
          <Route path="/*" element={<Navigate to="/" />} />
          <Route path="/prescription" element={<Prescription />} />
          <Route path="/prescription/:id" element={<AddPrescription />} />
          <Route path="/account_settings" element={<AccountSettings />} />
          <Route path="/change-password/:resetToken" element={<ChangePassword />} />
        </Routes>
      </Router>
    </>
  );
};

export default RoutesPage;
