import "./header.css";
import logo from "../../Assets/Images/blck-logo.png";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { Col, Row } from "react-bootstrap";

export default function Header() {
  const navigate = useNavigate();
  const cookies = new Cookies();

  const handleSignOut = async () => {
    cookies.remove("adminToken");
    sessionStorage.removeItem("adminToken");
    navigate("/");
  };
  return (
    <>
      {/* <div className="header-container fixed-top">
        <div className="header-col">
          <div className="header-row">
            <div className="header-logo-div">
              <img className='header-logo-img' src={logo} alt="Pic not found!" />
            </div>
            <div className="signout-div">
              <button className='signout' onClick={handleSignOut}>Sign Out</button>
            </div> */}
      {/* <div className="header-container fixed-top">
      <div className="header-col">
        <div className="header-row">
          <div className="header-logo-div">
            <img className='header-logo-img' src={logo} alt="Pic not found!" />
          </div>
          <div className="signout-div">
              <button className='signout' onClick={()=>navigate('/')}>Sign Out</button>
          </div>
          
        </div>
      </div>
    </div> */}
      <div className="header_main">
        <header className="position-relative">
          <div className="container-fluid p-0">
            <Row className=" header_inner">
              <Col lg={3} xxl={2} xl={2} className="p-0" xs={6}>
                <div className="main-logo-header d-flex align-items-center justify-content-center h-100 p-0">
                  {/* <Link to="/"> */}
                  <img src={logo} alt="logo" />
                  {/* </Link> */}
                </div>
              </Col>
              <Col lg={9} xxl={10} xl={10} xs={6}>
                <div className="d-flex justify-content-end pe-4">
                  <div className="d-flex  gap-2">
                    <button
                      onClick={handleSignOut}
                      // onClick={()=>navigate('/')}
                      className=" white-btn mt-0 signOut_btn topbar_signout_btn d-none d-lg-block"
                    >
                      Sign Out
                    </button>
                    <button
                      className="navbar-toggler collapse d-block d-lg-none"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#navbarNavDropdown"
                      aria-controls="navbarNavDropdown"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <span className="navbar-toggler-icon "></span>
                    </button>
                  </div>
                </div>
              </Col>
            </Row>
            {/* <ul className="mb-0 d-flex justify-content-between align-items-center header_inner">
              <li>
                
              </li>
              <li className='d-flex gap-4 align-items-center'>
               
              </li>
            </ul> */}
          </div>
        </header>
      </div>
    </>
  );
}
