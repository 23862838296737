import React from "react";
import { Button, Fade, Modal } from "@mui/material";
const ViewImageModal = ({ viewModalImg, setViewModalImg, image1 }) => {
  const handleClose = () => {
    setViewModalImg(false);
  };

  return (
    <>
      <Modal
        className={"modal_center"}
        open={viewModalImg}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <div className={"modal_center"}>
          <Fade className={"modal_center"} in={viewModalImg} timeout={500}>
            <img
              src={image1[0]}
              alt="asd"
              style={{ maxHeight: "90%", maxWidth: "90%" }}
            />
          </Fade>
        </div>
      </Modal>
    </>
  );
};

export default ViewImageModal;
