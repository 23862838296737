import { Button, Modal } from "react-bootstrap";

export default function CancelModal({ show, onConfirm, onCancel }) {
  return (
    <Modal show={show} onHide={onCancel}>
      <Modal.Header closeButton>
        <Modal.Title>Cancel Call</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to cancel the call?</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onCancel}>
          No
        </Button>
        <Button variant="danger" className="launch-btn" onClick={onConfirm}>
          Yes
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
