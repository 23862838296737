const HOST_NAME = process.env.REACT_APP_IMG_URL;

export function formatDateToDMY(dateString, type) {
  const date = new Date(dateString);

  const day = date.getUTCDate().toString().padStart(2, "0");
  const month = (date.getUTCMonth() + 1).toString().padStart(2, "0");
  const year = date.getUTCFullYear();
  if (type) {
    return `${year}-${month}-${day}`;
  } else {
    return `${day}/${month}/${year}`;
  }
}

// export function getFormattedTime(dateString) {
//   const date = new Date(dateString);

//   const options = {
//     hour: "2-digit",
//     minute: "2-digit",
//     hour12: true,
//   };

//   const formatter = new Intl.DateTimeFormat("en-US", options);
//   return formatter?.format(date);
// }

export function getFormattedTime(dateString) {
  const date = new Date(dateString);

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };

  const formatter = new Intl.DateTimeFormat("en-US", options);
  return formatter.format(date);
}

export function formatDate(inputDate) {
  // Convert the input to a Date object if it's not already
  const date = new Date(inputDate);

  // Check if date is valid
  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const monthsOfYear = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const dayOfWeek = daysOfWeek[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = monthsOfYear[date.getMonth()];

  // Get the appropriate suffix for the day of the month (st, nd, rd, th)
  const suffix =
    dayOfMonth % 10 === 1 && dayOfMonth !== 11
      ? "st"
      : dayOfMonth % 10 === 2 && dayOfMonth !== 12
      ? "nd"
      : dayOfMonth % 10 === 3 && dayOfMonth !== 13
      ? "rd"
      : "th";

  // Check if the provided date is today
  const today = new Date();
  const isToday = date.toDateString() === today.toDateString();

  return `${dayOfWeek}, ${dayOfMonth}${suffix} ${month}${
    isToday ? " (Today)" : ""
  }`;
}

export function getDateDescription(inputDate) {
  const date = new Date(inputDate); // Convert inputDate to a Date object

  // Create Date objects for today, tomorrow, and the day after tomorrow
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const dayAfterTomorrow = new Date();
  dayAfterTomorrow.setDate(today.getDate() + 2);

  // Normalize dates to ignore the time part for comparison
  const normalizeDate = (date) =>
    new Date(date.getFullYear(), date.getMonth(), date.getDate());

  // Compare the normalized dates
  if (normalizeDate(date).getTime() === normalizeDate(today).getTime()) {
    return "Today";
  } else if (
    normalizeDate(date).getTime() === normalizeDate(tomorrow).getTime()
  ) {
    return "Tomorrow";
  } else {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    // Return the date in the format date-monthName-year
    const day = date.getDate();
    const monthName = monthNames[date.getMonth()]; // Get month name
    const year = date.getFullYear();
    return `${day} ${monthName} ${year}`;
  }
}
export const isToday = (date) => {
  const today = new Date();
  return (
    date.getFullYear() === today.getFullYear() &&
    date.getMonth() === today.getMonth() &&
    date.getDate() === today.getDate()
  );
};

// export function timeAgo(inputTime) {
//     const date = new Date(inputTime);
//     const now = new Date();
//     const seconds = Math.floor((now - date) / 1000);

//     const intervals = [
//         { label: 'year', seconds: 31536000 },
//         { label: 'month', seconds: 2592000 },
//         { label: 'week', seconds: 604800 },
//         { label: 'day', seconds: 86400 },
//         { label: 'hour', seconds: 3600 },
//         { label: 'minute', seconds: 60 },
//         { label: 'second', seconds: 1 }
//     ];

//     for (const interval of intervals) {
//         const count = Math.floor(seconds / interval.seconds);
//         if (count >= 1) {
//             return count === 1
//                 ? `A ${interval.label} ago`
//                 : `${count} ${interval.label}s ago`;
//         }
//     }

//     return 'just now';
// }

export function timeAgo1(inputTime) {
  const date = new Date(inputTime);
  const now = new Date();
  const seconds = Math.floor((now - date) / 1000);

  if (seconds < 300) {
    return "Just now";
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  } else if (seconds < 86400) {
    const hours = Math.floor(seconds / 3600);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  } else {
    const days = Math.floor(seconds / 86400);
    return `${days} day${days > 1 ? 's' : ''} ago`;
  }
}


export function getImageUrl(path) {
  return HOST_NAME + "public/uploads/" + path;
}
export const options = { year: "numeric", month: "long" };

export function formatConsultationDate(dateString) {
  if (dateString) {
    const date = new Date(dateString);

    const options = { weekday: "short" };
    const day = date.toLocaleDateString("en-US", options);

    const dateOfMonth = String(date.getDate()).padStart(2, "0");

    return `${day}, ${dateOfMonth}`;
  }
}

export function shouldShowContactInfo(consultationDateString) {
  if (consultationDateString) {
    const consultationDate = new Date(consultationDateString);
    const currentDate = new Date();

    const diffInMs = consultationDate - currentDate;

    const diffInHours = diffInMs / (1000 * 60 * 60);

    return diffInHours <= 2 && diffInHours >= 0;
  }
}
