import Main from '../Main/Main'
// import './account_settings.css'


import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteAccount, suspendAccount } from '../../Redux/Actions/user/userAll';
// import {
//   getContactPref,
//   saveContactPref,
// } from "../../../Redux/Actions/user/userAll";



export default function AccountSettings() {

  const [contactInput, setContactInput] = useState({
    recieve_offers: "",
    recieve_confirmation: "",
    recieve_reminders: "",
  });
  const dispatch = useDispatch();
  const contactPref = useSelector((state) => state.consultaions.contactPref);
  useEffect(() => {
    // dispatch(getContactPref());
  }, []);

  useEffect(() => {
    if (contactPref) {
      setContactInput(contactPref);
    }
  }, [contactPref]);

  const handleClick = (ques, ans) => {
    setContactInput((prev) => {
      return {
        ...prev,
        [ques]: ans,
      };
    });
  };

  const updateContactPrefs = () => {
    // dispatch(saveContactPref(contactInput));
  };
  const handleAccountAction = async (type) => {
    const data = {
      status: "1"
    }
    try {
      if (type == 'delete') {
        await deleteAccount()
      } else {
        await suspendAccount(data)
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Main>
        <div className="desc_area pb-0">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="back-button mb-4">
                <Link to="/myaccount" className="white-btn">
                  Back
                </Link>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xl-9 col-lg-7 col-md-9 col-12 ">
              <div className="new_consultation_booking common_shadow bg-white min-h-100 ">
                <h2 className="common_title padding_36">Account Settings</h2>

                <div className="row">
                  <div className="col-lg-12">
                    <div className="personal_details contact_preferneces medical_history_details">
                      <div className="card">
                        <div className="card-body inner_padding personal_info_inner">

                          <div className="d-flex justify-content-between align-items-center mb-3 peronal-content-detail">

                            {/* <h6 className="mb-0 w-100">
                              Suspend Account
                            </h6> */}
                            <div className="options_part questions_options">
                              <ul className="mb-0 d-flex justify-content-end gap-3 p-0">
                                {/* <li>
                                  <a
                                    onClick={() =>
                                      handleClick("recieve_offers", "Yes")
                                    }
                                    className={
                                      contactPref
                                        ? contactInput?.recieve_offers == "Yes"
                                          ? "select_option_btn  box_shadow selected_color"
                                          : "select_option_btn  box_shadow"
                                        : "select_option_btn  box_shadow"
                                    }
                                  >
                                    Yes
                                  </a>
                                </li>
                                <li>
                                  <a
                                    onClick={() =>
                                      handleClick("recieve_offers", "No")
                                    }
                                    className={
                                      contactPref
                                        ? contactInput?.recieve_offers == "No"
                                          ? "select_option_btn  box_shadow selected_color"
                                          : "select_option_btn box_shadow"
                                        : "select_option_btn box_shadow"
                                    }
                                  >
                                    No
                                  </a>
                                </li> */}
                                {/* <li>
                                  <a
                                    onClick={() =>
                                      handleAccountAction('suspend')
                                    }
                                    className={
                                      contactPref
                                        ? contactInput?.recieve_confirmation ==
                                          "Yes"
                                          ? "select_option_btn box_shadow selected_color"
                                          : "select_option_btn  box_shadow"
                                        : "select_option_btn  box_shadow"
                                    }
                                  >
                                    Suspend
                                  </a>
                                </li> */}
                              </ul>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-items-center mb-3  peronal-content-detail">
                            <h6 className="mb-0 w-100">
                              Delete Account
                            </h6>
                            <div className="options_part questions_options">
                              <ul className="mb-0 d-flex justify-content-end gap-3 p-0">
                                {/* <li>
                                  <a
                                    onClick={() =>
                                      handleClick("recieve_confirmation", "Yes")
                                    }
                                    className={
                                      contactPref
                                        ? contactInput?.recieve_confirmation ==
                                          "Yes"
                                          ? "select_option_btn box_shadow selected_color"
                                          : "select_option_btn  box_shadow"
                                        : "select_option_btn  box_shadow"
                                    }
                                  >
                                    Yes
                                  </a>
                                </li>
                                <li>
                                  <a
                                    onClick={() =>
                                      handleClick("recieve_confirmation", "No")
                                    }
                                    className={
                                      contactPref
                                        ? contactInput?.recieve_confirmation ==
                                          "No"
                                          ? "select_option_btn  box_shadow selected_color"
                                          : "select_option_btn box_shadow"
                                        : "select_option_btn  box_shadow"
                                    }
                                  >
                                    No
                                  </a>
                                </li> */}
                                <li>
                                  <a
                                   style={{cursor: "pointer"}}
                                    onClick={() =>
                                      handleAccountAction('delete')
                                    }
                                    className={
                                      contactPref
                                        ? contactInput?.recieve_confirmation ==
                                          "Yes"
                                          ? "select_option_btn box_shadow selected_color "
                                          : "select_option_btn  box_shadow "
                                        : "select_option_btn  box_shadow px-4"
                                    }
                                  >
                                    Delete
                                  </a>
                                </li>

                              </ul>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
   
    </Main>
  )
}