import Main from "../Main/Main";
// import './addprescription.css'
import searchImg from "../../Assets/Icons/search.svg";
import lessarrow from "../../Assets/Icons/lessarrow.svg";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import ViewPrescription from "../View Prescription/ViewPrescription";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import { medicationArr } from "./medication_list";
import {
  createPrescription,
  getClientList,
  getPrescriptions,
} from "../../Redux/Actions/user/userAll";
import { formatDateToDMY } from "../../Utils/commonFiles";
import { useReactToPrint } from "react-to-print";
import { toastInfo } from "../../Redux/Reducers/admin/adminPanelSlice";

export default function AddPrescription() {
  const [showModal, setShowModal] = useState(false);
  const [viewP, setViewP] = useState();
  const [view, setView] = useState();

  const [selectedClient, setSelectedClient] = useState("");
  const handleClose = () => {
    setShowModal(false);
  };

  const PrescriptionRef = useRef();
  const handlePresPrint = useReactToPrint({
    content: () => PrescriptionRef.current,
  });
  const navigate = useNavigate();
  const { id } = useParams();
  const prescriptions = useSelector(
    (state) => state.consultaions.prescriptions
  );

  const dispatch = useDispatch();
  const [viewPres, setViewPres] = useState(false);
  const [slectedMed, setSlectedMed] = useState();
  const [slectedMed2, setSlectedMed2] = useState();
  const [viewPresData, setViewPresData] = useState(null);
  const [medication, setMedication] = useState([
    {
      meds: "",
      quantity: "",
    },
  ]);
  const [supply, setSupply] = useState("");
  const [medsArray, setMedsArray] = useState([]);
  var today = new Date().toISOString().split("T")[0];

  useEffect(() => {
    // dispatch(getMemberDetails(id));
    if (id === "add") return;
    let data = {
      id,
    };
    dispatch(getPrescriptions(data));
  }, []);


  const handleSelectBox = (medicationValue) => {
    const selectedData = medicationArr?.find(
      (item) => item.Medication === medicationValue
    );
    setSupply(selectedData?.Supply || "");
    setSlectedMed(medicationValue);

  };

  const handleMedsSubmit = () => {
    setSlectedMed2("");
    setSupply("");
    if (slectedMed) {
      const data = medicationArr?.filter(
        (item) => item.Medication == slectedMed
      )[0];
      if (medsArray?.length >= 4) {
        // toastError("Limit Reached");
      } else {
        setMedsArray((prevVal) => {
          return [
            ...prevVal,
            {
              meds: data?.Medication,
              quantity: data?.Supply,
            },
          ];
        });
        setMedication({
          meds: "",
          quantity: "",
        });
      }
    }
  };

  const validate = (values) => {
    const errors = {};
    if (!values.date) {
      errors.date = "Required";
    }
    if (!values.notes) {
      errors.notes = "Required";
    }
    return errors;
  };

  const initialValues = {
    date: "",
    notes: "",
  };

  const formik = useFormik({
    initialValues: {
      date: "",
      notes: "",
      // medications: [
      //   {
      //     meds: "",
      //     quantity: "",
      //   },
      // ],
      medication: [],
    },
    validate,
    onSubmit: async (values) => {
      // values.id = selectedClient || id;

      values.id = selectedClient || id;
      if (values.medication.length < 1) {
        // toastInfo("Please add medications");
      }

      let arr = []
      if (medsArray && medsArray.length > 0) {

        arr = [...medsArray]; 

        arr.push({
          meds: slectedMed2,
          quantity: supply,
        });
        values.medication  = arr
      } else if (slectedMed2 && supply) {
        values.medication = [
          {
            meds: slectedMed2,
            quantity: supply,
          },
        ];
      } else {
        toastInfo("Please add medications");
        return; 
      }

      try {
        dispatch(createPrescription(values));
        if (id === 'add') {
          navigate(`/prescription`);
        } else {
          navigate(`/client/${id}`);
        }
        formik.resetForm();
      } catch (error) {
        console.log(error);
      }
    },
  });

  const handleBack = () => {
    if (!viewPres) {
      navigate(-1);
    } else {
      setViewPres(false);
    }
  };

  const handleViewPres = (pres) => {
    setViewPres(true);
    setViewPresData(pres);
  };

  const removeMed = (index) => {
    setMedsArray((prev) => {
      return prev.filter((item, i) => i != index);
    });
  };

  const data = useSelector((state) => state.consultaions.clientList);

  useEffect(() => {
    let data = {
      page: 1,
      limit: 10,
    };
    dispatch(getClientList(data));
  }, []);


  return (
    <Main>
      <div className="container-fluid py-3 h-100">
        <div className="row py-2">
          <div className="col d-flex justify-content-between align-items-center">
            <div className="title">
              <h2 className="booking-h2 ">New Prescriptions</h2>
              {/* <div className='d-flex align-items-top '>
                <Link className='p-1'><img src={lessarrow} alt="arrow" className='lessarrow bg-white rounded p-1' />
                </Link>
                <h3 className='customer-name ps-2 fw-normal'>Simon James</h3>
              </div> */}
            </div>
            {id !== "add" && (
              <div className=" px-3 py-2 ">
                <form class="bg-white px-3 py-2 search-outer">
                  <div className="search-div position-relative text-end">
                    <input
                      className="search-img ms-3 position-absolute top-50 translate-middle "
                      type="image"
                      src={searchImg}
                      alt="pic not found"
                    />
                    <input
                      className=" search py-2"
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                    />
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>

        <div className="row p-0 ">
          <div className="col-lg-6  ps-0">
            <div className="create-form  bg-white ">
              <div className="create-title">
                <h6 className="common_title">Create New Prescription</h6>
              </div>
              <form onSubmit={formik.handleSubmit}>
                <div className="row px-3 py-4">
                  <div className="col-md-12 px-4">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <label className="view-label-input">Date</label>
                          <input
                            onChange={formik.handleChange}
                            type="date"
                            name="date"
                            placeholder="01/01/2023"
                            className="view-label-input form-control select_input"
                            value={formik.values.date}
                            min={today}
                          />
                          {formik.errors.date && (
                            <p className="formik-errors">
                              {formik.errors.date}
                            </p>
                          )}
                        </div>
                      </div>
                      {id === "add" && (
                        <div className="col-12">
                          <div className="form-group mb-3">
                            <label className="view-label-input">Client</label>
                            <select
                              onChange={(e) => {
                                // handleSelectBox(e.target.value);
                                setSelectedClient(e.target.value);
                              }}
                              // value={slectedMed2}
                              // name="meds"
                              className="view-label-input form-control select_input"
                            >
                              <option>Select</option>
                              {data?.list?.map((item) => {
                                return (
                                  <>
                                    <option value={item?._id}>
                                      {item?.firstName + " " + item?.lastName}
                                    </option>
                                  </>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      )}
                      <div className="col-md-5">
                        <div className="form-group mb-3">
                          <label className="view-label-input">Medication</label>
                          <select
                            onChange={(e) => {
                              handleSelectBox(e.target.value);
                              setSlectedMed2(e.target.value);
                            }}
                            value={slectedMed2}
                            name="meds"
                            className="view-label-input form-control select_input"
                          >
                            <option>Select</option>
                            {medicationArr?.map((item) => {
                              return (
                                <>
                                  <option value={item?.Medication}>
                                    {item?.Medication}{" "}
                                  </option>
                                </>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-md-5">
                        <div className="form-group mb-3">
                          <label className="view-label-input">Quantity</label>
                          <input
                            //  onChange={handleMedsChange}
                            // type="number"
                            name="quantity"
                            // placeholder="Type here"
                            className="view-label-input form-control select_input"
                            value={supply}
                            disabled
                          />
                        </div>
                      </div>
                      <div className="col-md-2 pt-4 ps-3">
                        <div
                          onClick={handleMedsSubmit}
                          className="add-cirle-p cursor-pointer"
                        >
                          <span className="plus-btn px-2 cursor-pointer text-white py-1">
                            +
                          </span>
                        </div>
                      </div>
                      {medsArray?.length > 0
                        ? medsArray.map((res, i) => {
                            return (
                              <>
                                <div className="col-md-6 mb-3 ">
                                  <div className="view-label-input select_input form-control">
                                    {res?.meds}
                                  </div>
                                </div>
                                <div className="col-md-5 mb-3 ">
                                  <div className="view-label-input select_input form-control">
                                    {res?.quantity}
                                  </div>
                                </div>
                                <div className="col-md-1 mb-3 ">
                                  <div
                                    onClick={() => removeMed(i)}
                                    className="add-cirle-p cursor-pointer d-flex align-items-center justify-content-end h-100"
                                  >
                                    <span>-</span>
                                  </div>
                                </div>
                              </>
                            );
                          })
                        : null}

                      <div className="col-md-12">
                        <div className="form-group mb-3 mt-4 prescripion_notes">
                          <label className="view-label-input">
                            Add Instructions / Dose Field
                          </label>
                          <textarea
                            rows={5}
                            onChange={formik.handleChange}
                            name="notes"
                            style={{ resize: "none" }}
                            className="form-control select_input"
                            placeholder="Type here..."
                            value={formik.values.notes}
                          ></textarea>
                          {formik.errors.notes && (
                            <p className="formik-errors">
                              {formik.errors.notes}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-12 px-4">
                    <div className="create_new_btn p-2 pb-4">
                      <button type="submit" className="btn cre_new">
                        Create
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {id !== "add" && (
            <div className="col-lg-6 col-12 pe-0  ">
              <div className=" bg-white table_scroll scrollbar-hide ad-card-prop">
                {showModal && (
                  <ViewPrescription
                    handleClose={handleClose}
                    viewPresData={viewP}
                    handlePrint={handlePresPrint}
                  />
                )}
                <div className="create-title ">
                  <h6 className="common_title ">Prescriptions</h6>
                </div>
                <div>
                  <Table>
                    <Table className="all_consultations " responsive>
                      <thead className="mb-2 table_head">
                        <tr className="">
                          <th className="w-25">Issue Date</th>
                          <th className="td_width_item">Item</th>
                          <th className="">Quantity</th>
                          <th> </th>
                        </tr>
                      </thead>
                      <tbody className="table_body">
                        {prescriptions?.data?.map((res, index) => (
                          <tr className="">
                            <div className="table-rows d-flex gap-4">
                              <td>{formatDateToDMY(res?.date)}</td>
                              <td className="td_width_item">
                                {res.medication.map((medsRes) => (
                                  <p>{medsRes?.meds}</p>
                                ))}
                              </td>
                              <td className="td_width_quantity">
                                {res.medication.map((qnty) => (
                                  <p>{qnty?.quantity}</p>
                                ))}
                              </td>
                              <td>
                                <button
                                  onClick={() => {
                                    setShowModal(true);
                                    setViewP(res);
                                  }}
                                  className="table_link_ text-decoration-underline text-color border-0 bg-transparent "
                                >
                                  View
                                </button>
                              </td>
                            </div>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Table>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </Main>
  );
}
