import React, { useEffect, useRef, useState } from "react";
import { Button, Container, Modal } from "react-bootstrap";
import Main from "../Main/Main";
import { useNavigate, useParams } from "react-router-dom";
import { Decryptedid } from "../../Utils/bcrypt";
import { useDispatch, useSelector } from "react-redux";
import Cookies from "universal-cookie";
import { startstopLoading } from "../../Redux/Reducers/globalSlice";
import { addDentistReviewtoConsultation, getConsultationById } from "../../Redux/Actions/user/userAll";
import MomentFunc from "../../Utils/momentDateTime";
import VideoCall from "./VideoCall";
import { io } from "socket.io-client";
import ChatBox from "./ChatBox";
import ConsNotes from "./ConsNotes";
import { Rating } from "react-simple-star-rating";
import { toast } from "react-toastify";

const ConsultationWEBRTC = () => {
  const { id } = useParams();
  const navigate = useNavigate()
  const consultantId = id && Decryptedid(atob(id));

  const cookies = new Cookies();
  const BASE_URL = process.env.REACT_APP_HOST_NAME;
  const [socket, setSocket] = useState();
  const socketRef = useRef();

  const [onlineUsers, setOnlineUsers] = useState();
  const [rating, setRating] = useState(0);
  const [state, setState] = useState({
    video_call_rating: rating,
    video_call_comment: "",
  });

  const [me, setMe] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const dispatch = useDispatch();

  const consultationData = useSelector(
    (state) => state?.consultaions?.consultationDetail
  );

  useEffect(() => {
    dispatch(startstopLoading(true));
    socketRef.current = io.connect(BASE_URL);
    socketRef.current.emit("video-user-add", "doctor");
    socketRef.current.on("me", (myId) => setMe(myId));
    setSocket(socketRef.current);
    dispatch(getConsultationById(consultantId)).then((res) => {
      if (res?.payload?.success == true || res?.payload?.success == false) {
        dispatch(startstopLoading(false));
      }
    });
    return () => {
      socketRef.current.disconnect();
    };
  }, []);

  useEffect(() => {
    socketRef.current.on("video-users", (users) => {
      setOnlineUsers(users);
    });
  }, [socketRef.current]);


  const handleSubmit = async () => {
    try {
      let obj = { ...state, consultation_id: id }
      await dispatch(addDentistReviewtoConsultation(obj)).then(() => {
        handleClose();
        navigate("/bookings?view_type=Day")
        toast.success("Thank you for your review.");
      });
    } catch (err) {
      console.log(err);
    }
  };

  const handleRating = (rate) => {
    setRating(rate);
    setState({
      ...state,
      video_call_rating: rate,
    });
  };

  const modalFunc = () => {
    setShow(true);
  }



  return (
    <Main>
      <div className="bookings ">
        <Container fluid className="p-0">
          <div id="layout-wrapper" className="bg-dash ">
            <div className="main-content">
              <div className="page-content">
                <div className="container-fluid">
                  <div className="row">
                    {consultationData && (
                      <div className="col-12">
                        <div className=" cons-top-bar page-title-box d-sm-flex align-items-center justify-content-between">
                          <div>
                            <span className="small_text">Consultation</span>
                            <h4 className="mb-sm-0 font-size-28">
                              Consultation with{" "}
                              <span
                                // onClick={handleUserClick}
                                className="name_color cursor-pointer"
                              >
                                {consultationData?.cons?.user_id?.firstName +
                                  " " +
                                  consultationData?.cons?.user_id?.lastName}
                              </span>
                            </h4>
                            <div className="mt-2 sub_head p-0">
                              <h4 className="sub-heading-text-header ms-2 ">
                                {MomentFunc.Date(consultationData?.cons?.date)}
                                <span className="">
                                  {MomentFunc.Time(
                                    consultationData?.cons?.date
                                  )}
                                </span>
                              </h4>
                            </div>
                          </div>
                          <div className="page-title-right"></div>
                        </div>
                      </div>
                    )}
                    <div className="col-lg-12">
                      <div className="desc_area pb-0">
                        <div className="row">
                          <div className="col-lg-7">
                            {socket && me && consultationData && (
                              <VideoCall
                                me={me}
                                consInfo={consultationData?.cons}
                                socket={socket}
                                onlineUsers={onlineUsers}
                                modalFunc={modalFunc}
                              />
                            )}
                            {consultationData && socket ? (
                              <ChatBox
                                socket={socket}
                                consInfo={consultationData}
                              />
                            ) : null}
                          </div>
                          {consultationData && socket ? (
                          <ConsNotes socket={socket} consInfo={consultationData} />
                         ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>

      <Modal show={show} onHide={handleClose} className="rating-main-popup">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="rating-sec">
            <div className="row">
              <div className="col-lg-12">
                <h5 className="text-center mb-3">Rate us the quality of video call service</h5>
              </div>
              <div className="col-lg-12 d-flex justify-content-center star-pattern">
                <Rating onClick={handleRating} initialValue={rating} />
              </div>
              <div className="col-lg-12 mt-4">
                <h5>Review(Optional)</h5>
                <textarea
                  rows={3}
                  placeholder=""
                  className="w-100"
                  onChange={(e) =>
                    setState({
                      ...state,
                      video_call_comment: e.target.value,
                    })
                  }
                ></textarea>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <button variant="primary" className=" new-pres" onClick={handleSubmit}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>
    </Main>
  );
};

export default ConsultationWEBRTC;
