import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { addConsMessage, addConvoArray } from "../../Redux/Reducers/chatSlice";
import { Decryptedid } from "../../Utils/bcrypt";

const ChatBox = ({ socket, consInfo }) => {
  const dispatch = useDispatch();
  const [text, setText] = useState("");
  const [convoId, setConvoId] = useState();
  const [stateForScroll, setStateForScroll] = useState();
  const ref = useChatScroll(stateForScroll);
  const conversation = useSelector((state) => state.chat.consultationConvo);

  const { id } = useParams();
  const consultantId = id && Decryptedid(atob(id));

  useEffect(() => {
    socket.on("recieve_cons_message", (messageObject) => {
      dispatch(addConsMessage(messageObject));
      setStateForScroll(messageObject.message);
    });
  }, [socket]);

  useEffect(() => {
    if (consultantId == consInfo?.cons?._id) {
      const chatArray = consInfo?.chat?.chatsArray;
      dispatch(addConvoArray(chatArray));
      if (consInfo?.chat?.conversationId) {
        const convo = consInfo?.chat?.conversationId;
        setConvoId(convo);
        (async () => await socket.emit("join_room", convo))();
      }
    }
  }, [consultantId, consInfo]);

  function useChatScroll(dep) {
    const ref = useRef();
    useEffect(() => {
      if (ref.current) {
        ref.current.scrollTop = ref.current.scrollHeight;
      }
    }, [dep]);
    return ref;
  }

  const keypress = (e) => {
    if (e.key === "Enter") {
      // console.log("dasd");
      sendText(e);
    }
  };

  const sendText = (event) => {
    event.preventDefault();
    if (text) {
      const messageObject = {
        convoId: convoId,
        message: text,
        sender: "doctor",
        time: JSON.stringify(new Date()),
      };

      socket.emit("send_cons_message", messageObject);
      dispatch(addConsMessage(messageObject));
      setStateForScroll(text);
      setText("");
    }
  };

  return (
    <div className="h-337 call_section">
      <div className="card h-100">
        <div className="card-body p-0 padding0">
          <div className="live_chat">
            <div className="live_chat_title">
              <h3 className="common_title">Live Chat</h3>
            </div>
            <form onSubmit={sendText}>
              <div ref={ref} className="overflow_scroll">
                <div className="chat">
                  {conversation?.length > 0 &&
                    conversation?.map((msg, index) => {
                      return msg?.sender === "doctor" ? (
                        <div
                          key={index}
                          className="right-side-chat admin-text-right"
                        >
                          <span className="small_parah color_grey">
                            {msg?.message}
                          </span>
                        </div>
                      ) : (
                        <div
                          key={index}
                          className="left-side-chat admin-text-left"
                        >
                          <span className="small_parah color_grey">
                            {msg?.message}
                          </span>
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="chat_send">
                <div className="d-flex justify-content-between">
                  <textarea
                    onChange={(e) => setText(e.target.value)}
                    placeholder="Type here..."
                    className="form-control chat_text"
                    value={text}
                    onKeyDown={keypress}
                  ></textarea>
                  <input type="submit" name="submit" value="Send" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBox;
